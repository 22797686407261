import React from "react";

import Layout from "../components/layout";
import ChevronLink from '../components/chevronlink';
import SEO from "../components/seo";
import TripleImage from '../components/tripleimage';
import { Link } from "gatsby";


function LostSomeone() {
  return (
    <Layout>
      <SEO title="Lost Someone" 
        description="For those who have lost someone to suicide and need help"
      />
      <div>
        <div className="container mx-auto px-2 md:py-24">
        <Link to="/"><div className="font-bold uppercase text-xs underline mb-4">Back home</div></Link>
          <div className="flex flex-col md:flex-row items-center md:-mx-2">
            <div className="w-full md:w-4/12 px-2">
              
              <h2 className="text-3xl py-2 lg:pr-24 leading-tight">I've lost someone to suicide and need help</h2>
              <p className="text-primary py-2 lg:pr-12 leading-relaxed">Losing a friend or family member to suicide can be a life changing event. Some people who have lost a loved one to suicide describe the loss as different to other deaths they have experienced.</p>
              <p className="text-primary py-2 lg:pr-12 leading-relaxed">The sudden and unexpected nature of the death can leave people with feelings of shock, confusion, anger and guilt. Gaining support in moving through this time and understanding the impact on you is an important step forward.</p>
              <p className="text-primary py-2 lg:pr-12 leading-relaxed">Provided below are links to information you can read and services you can access to help you through this time. </p>

            </div>
            <div className="w-full md:w-8/12 px-2 order-first md:order-none">
            <div className="p-4">
              <TripleImage 
                image1="3.jpg"
                image2="2.jpg"
                image3="6.jpg"
                mode={3}
               />
               </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container px-2  mx-auto py-12">
          <div className="flex flex-col md:flex-row md:-mx-2">
            <div className="w-full md:w-4/12 px-2">
            <div className="bg-box1 h-full p-6 rounded">
              <h3 className="text-2xl py-2 font-bold">Something to read</h3>
              <p className="text-primary py-2 leading-relaxed">Information on what grief looks like, how to recover from grief and activities to help with grief can be found <a target="_blank" rel="noopener noreferrer" href="https://www.beyondblue.org.au/the-facts/suicide-prevention/after-a-suicide-loss/suicide-and-grief" className="underline">here.</a></p>
              <p className="text-primary py-2 leading-relaxed">Standby Support after Suicide is a program of support offered across Australia, including Brisbane North, for people bereaved by suicide. Standby offers a range of resources that you may find helpful to read. You can access them <a target="_blank" rel="noopener noreferrer" href="http://standbysupport.com.au/resources/" className="underline">here.</a></p>
            </div>
            </div>
            <div className="w-full md:w-4/12 px-2">
            <div className="bg-box2 h-full p-6 rounded">
              <h3 className="text-2xl py-2 font-bold">Speak to someone</h3>
              <p className="text-primary py-2 leading-relaxed">If you wish to speak to someone other than a family member or friend, accessing support through a <span className="font-bold">helpline</span> may be a good option.</p>
              <p className="text-primary py-2 leading-relaxed">A list of helplines you can contact is available&nbsp;<a target="_blank" rel="noopener noreferrer" href="https://www.mymentalhealth.org.au/page/services/community-services/sp-care-pathways/self-guided-support/helplines/" className="underline">here</a>.</p>
              <p className="text-primary py-2 leading-relaxed">You may also wish to speak to a GP. A GP can make a diagnosis, discuss suitable treatment options and refer you to a mental health professional if needed. You can search for a General Practice near you by <a target="_blank" rel="noopener noreferrer" href="https://www.mymentalhealth.org.au/page/services/general-practice-and-allied-health/general-practice/" className="underline">clicking&nbsp;here.</a></p>
            </div>
            </div>
            <div className="w-full md:w-4/12 px-2">
            <div className="bg-box3 h-full p-6 rounded">
            <h3 className="text-2xl py-2 font-bold">Access professional help</h3>
              <p className="text-primary py-2 leading-relaxed">Standby Support after Suicide offers free face to face and 24/7 crisis telephone support, with follow up contact continued for up to two years for anyone impacted by suicide. Call <a className="whitespace-no-wrap underline" href="tel:0438150180">0438 150 180</a> in Brisbane North (24 hours a day, seven days a&nbsp;week).</p>
              <p className="text-primary py-2 leading-relaxed">A range of other mental health services that provide one-on-one support are available in the Brisbane North region. For help in finding the service and supports that will best meet your needs, please contact the My Mental Health Service Navigators by calling <a className="whitespace-no-wrap underline" href="tel:1800752235">1800 752 235</a>.</p>
              <p className="text-primary py-2 leading-relaxed">Please note, this is not a crisis service and operates 8.30 am - 4.30 pm, Monday to Friday. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section >
          <div className="container mx-auto">
            <div className="flex flex-col items-center p-4 md:pt-8 md:pb-16">
              <ChevronLink to="/support-someone">I'd like to support someone who has lost a family member or friend to suicide</ChevronLink>
          </div>
          </div>
      </section>
    </Layout>
  );
}

export default LostSomeone;
